
import { MapBoxApiGeolocationPayload } from "@/utility";
import { Collection } from "@vuex-orm/core";
import mapboxgl, { LngLatLike, Map, Marker } from "mapbox-gl";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DateFormatMixin } from "../../../mixins";
import { GeoLocation, Technician } from "../../../store/modules";
// import { async } from 'rxjs/internal/scheduler/async';

export interface MapAddress {
  address: string;
  enablePopup: boolean;
  popupHtml?: string;
  color?: string;
}

export interface AddressProcessPayload {
  address: MapAddress;
  geo: MapBoxApiGeolocationPayload | null;
}

/*const icon = (() => {
  const src = `<svg width="20" height="20" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" fill="none" r="10" stroke="#383a36" stroke-width="2">
    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
  </circle>
  <circle cx="20" cy="20" fill="#383a36" r="10"/>
</svg>`;
  const parser = new DOMParser();
  return parser.parseFromString(src, "image/svg+xml");
})();*/

@Component({
  components: {},
})
export default class TechnicianMap extends mixins(DateFormatMixin) {
  map?: Map;

  @Prop([Array, Object])
  center?: LngLatLike;

  @Prop({ default: 12, type: Number })
  zoom!: number;

  @Prop({ default: () => [] })
  locationMarkers!: Collection<GeoLocation>;

  markers: Marker[] = [];

  markersAddresses: Marker[] = [];

  @Prop({ default: () => [], type: Array })
  taskAddresses!: MapAddress[];

  @Prop({ default: () => [], type: Array })
  jobAddresses!: MapAddress[];

  @Prop({ default: () => [], type: Array })
  addresses!: MapAddress[];

  async drawAddressMarkers() {
    if (!this.map) {
      console.error("Missing map instance");
      return;
    }

    this.markersAddresses.forEach((m) => m.remove());

    this.processAndRenderAddresses(this.addresses);
    this.processAndRenderAddresses(this.taskAddresses);
    this.processAndRenderAddresses(this.jobAddresses);
    // const addresses = await this.processAddress(this.addresses);
    // addresses
    //   .map((data) => this.createMarkerAddress(data))
    //   .forEach((marker) => {
    //     if (!this.map) {
    //       return;
    //     }

    //     if (!marker) {
    //       return;
    //     }

    //     this.markersAddresses.push(marker);
    //     marker.addTo(this.map);
    //   });
  }

  async processAndRenderAddresses(addresses: MapAddress[]) {
    const processedAddress = await this.processAddress(addresses);

    processedAddress
      .map((data) => this.createMarkerAddress(data))
      .forEach((marker) => {
        if (!this.map) {
          return;
        }

        if (!marker) {
          return;
        }

        // marker.scale

        this.markersAddresses.push(marker);
        marker.addTo(this.map);
      });
  }

  createMarkerAddress(data: AddressProcessPayload | null) {
    if (!data) {
      return null;
    }
    const { address, geo } = data;

    if (!geo || !geo.features || !geo.features.length) {
      return null;
    }

    // console.log('create marker address', data)
    const marker = new Marker({
      color: address.color || "#aa1212",
      scale: 0.75
    });
    const feature = geo.features[0];
    // console.log('creating', [location.long, location.lat]);
    marker.setLngLat(feature.center);
    if (address.enablePopup) {
      const popup = new mapboxgl.Popup({ offset: 10 });
      if (address.popupHtml) {
        popup.setHTML(address.popupHtml);
      } else {
        popup.setHTML(feature.place_name);
      }

      marker.setPopup(popup);
    }

    return marker;
  }

  async processAddress(addresses: MapAddress[]): Promise<Array<AddressProcessPayload | null>> {
    // const client = mbxClient({
    //   accessToken: process.env.VUE_APP_MAP_BOX
    // });
    // const endpoint = 'mapbox.places-permanent';
    const endpoint = "mapbox.places";
    const token = `${process.env.VUE_APP_MAP_BOX}`;
    const baseUrl = "https://api.mapbox.com/geocoding/v5";
    const storage = localStorage;
    // const addresses = this.addresses;
    return await Promise.all(
      addresses.map(async (address) => {
        const url = new URL(`${baseUrl}/${endpoint}/${address.address}.json`);
        url.searchParams.append("access_token", `${token}`);
        /*const cache = storage.getItem(address);
      if (cache) {
        return JSON.parse(cache);
      }*/

        const response = await fetch(`${url}`);
        if (response.status !== 200) {
          return null;
        }

        const data = await response.json();

        if ("features" in data && data.features.length) {
          return { address, geo: data };
        }

        return null;
      })
    );
    // Object.keys(params).forEach(key => url.searchParams.append(key, ''+params[key]))
  }

  mounted() {
    console.log("mounted", this.$el);
    this.initMap();
  }

  beforeDestroy() {
    console.log("beforeDestroy");
    this.destroyMap();
  }

  @Watch("locationMarkers")
  onLocationChange(markers: Collection<GeoLocation>) {
    this.drawMarkers(markers);
  }

  @Watch("addresses")
  onAddressChange(addresses: MapAddress[]) {
    this.drawAddressMarkers();
  }

  @Watch("taskAddresses")
  onTaskAddressChange(addresses: MapAddress[]) {
    this.drawAddressMarkers();
  }

  @Watch("jobAddresses")
  onJobAddressChange(addresses: MapAddress[]) {
    this.drawAddressMarkers();
  }

  @Watch("center")
  onCenterChange(center: LngLatLike) {
    this.map && this.map.setCenter(center);
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: this.$el as HTMLElement,
      style: "mapbox://styles/mapbox/light-v10",
      zoom: this.zoom,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.resize();

    if (this.center) {
      this.map.setCenter(this.center);
    }
    this.map.on("load", () => this.mapLoaded());
  }

  destroyMap() {
    if (!this.map) {
      return;
    }
    // stop all transactions
    this.map.stop();
    this.map.off("load", () => this.map);
    // explictly call remove to remove workers
    this.map.remove();
    this.map = undefined;
  }

  mapLoaded() {
    console.warn("mapLoaded");
    if (this.locationMarkers.length) {
      this.drawMarkers(this.locationMarkers);
    }

    this.$nextTick(() => this.drawAddressMarkers());
  }

  updated() {
    console.log("updated");
  }

  drawMarkers(locations: Collection<GeoLocation>) {
    this.markers.forEach((marker) => {
      if (marker) {
        marker.remove();
      }
    });
    this.markers.length = 0;
    const markers: Marker[] = [];
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];

      const marker = this.createMarker(location, {
        color: `#${this.color(location)}`,
      });
      markers.push(marker);
    }

    this.markers = markers;

    this.$nextTick(() => {
      markers.forEach((marker) => this.map && marker.addTo(this.map));
      // this.$nextTick(() => this.drawAddressMarkers());
    });
  }

  color(loc: GeoLocation) {
    if (!loc.tech_id) {
      return "#000000";
    }

    const tech = Technician.query().find(loc.tech_id);
    if (!tech) {
      return "#000000";
    }

    return tech.hexColor;

    // const techName = `${tech.firstname}${tech.lastname}`;

    // if (!techName) {
    //   return "#000000";
    // }
    // // https://www.designedbyaturtle.co.uk/convert-string-to-hexidecimal-colour-with-javascript-vanilla/
    // const i = techName
    //   .split("")
    //   .map(s => s.charCodeAt(0))
    //   .reduce((acc, s) => {
    //     return s + (acc << 5) - acc;
    //   }, 0);

    // let hex =
    //   ((i >> 24) & 0xff).toString(16) +
    //   ((i >> 16) & 0xff).toString(16) +
    //   ((i >> 8) & 0xff).toString(16) +
    //   (i & 0xff).toString(16);
    // // Sometimes the string returned will be too short so we
    // // add zeros to pad it out, which later get removed if
    // // the length is greater than six.
    // hex += "000000";

    // return hex.substring(0, 6);
  }

  createMarker(location: GeoLocation, opt: mapboxgl.MarkerOptions = {}) {
    const marker = new mapboxgl.Marker(opt);
    // console.log('creating', [location.long, location.lat]);
    marker.setLngLat([location.long, location.lat]);
    const popup = new mapboxgl.Popup();
    marker.setPopup(popup);

    popup.on("open", async () => {
      popup.setHTML("loading");

      if (!location.tech_id) {
        popup.setHTML("Unknown Technician");
        return;
      }

      const tech = Technician.query().find(location.tech_id);

      if (!tech) {
        popup.setHTML(`Unknown Technician with id ${location.tech_id}`);
        return;
      }

      popup.setHTML(
        [
          `<a href="#/technician/${tech.ID}">${tech.firstname} ${tech.lastname}</a>`,
          // `<p>${this.formatCheckYear(location.time)}</p>`,
          `<p>Last seen ${this.fromNowLocal(location.time)}.</p>`,
          location.mr_id
            ? `<p> View <a href="#/mr/${location.mr_id}">Maintenance Record</a></p>`
            : "",
        ].join("")
      );
    });

    // marker.getElement().addEventListener('click', () => {
    //   console.log('test');
    // popup.setHTML('loading');
    // setTimeout(() => {
    //   popup.setHTML('test');
    // }, 1000)
    // })
    return marker;
  }

  /*mapUnload() {
    console.warn('mapUnloaded');
  }*/
}
